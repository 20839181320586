<template>
  <div class="final-page">
    <div class="final-page__top">
      <Top
        :description="`
                    Для гарантированного получения займа подайте заявки<br/>
                    как минимум в 3 компании.
                `"
        :showCalc="false"
        :title="`
                    Лучшие предложения<br/>
                    для вас!
                `"
        type="final"
      />
      <div v-if="offers" class="final-page__content">
        <div class="final-page__offers">
          <div
            v-for="(offer, index) in offers"
            :key="index"
            class="final-page__offer offer"
          >
            <div class="offer__content">
              <p v-if="offer.isFeatured" class="offer__special">
                <span>0%</span><br />
                ставка
              </p>
              <div class="offer__logo">
                <img :alt="offer.title" :src="offer.logo" />
              </div>
              <div class="offer__info">
                <div>
                  <p class="offer__subtitle">Максимальная сумма:</p>
                  <p class="offer__title">{{ offer.header }} ₽</p>
                </div>
                <div>
                  <p class="offer__subtitle">Вероятность получения:</p>
                  <p class="offer__chance">{{ offer.chance }}%</p>
                </div>
              </div>
              <a :href="offer.link" class="offer__action" target="_blank">
                Получить
              </a>
              <p class="offer__subtitle">
                {{ offer.subHeader }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import "./final-page.scss";
import Top from "@index/components/common/top/Top";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Final",
  data() {
    return {
      showMore: false,
      response: [],
    };
  },
  created() {
    this.getOffers();
  },
  watch: {
    response: function (newQuestion) {
      console.log(newQuestion);
    },
  },
  methods: {
    async getOffers() {
      const response = await axios.get("/api/final");
      // @TODO предусмотреть ошибку? тест
      this.response = response.data;
    },
    openOffer(link) {
      window.location.href = link;
    },
  },
  computed: {
    ...mapGetters({
      user: "application/user",
    }),
    offers() {
      if (!this.showMore) return this.response.targets?.default;

      return [
        ...this.response.targets?.default,
        ...this.response.targets?.other,
      ];
    },
    username() {
      return (
        this.response.username || this.user.contactData?.firstname || "гость"
      );
    },
  },
  components: {
    Top,
    Footer: () => import("@index/components/main/footer/Footer"),
  },
};
</script>
