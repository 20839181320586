import store from "@index/store";

export default async (to, from, next) => {
  if (!store.state.application.init) {
    await Promise.all([store.dispatch("application/update")]);
    await store.dispatch("dictionary/init");
  }

  next();
};
