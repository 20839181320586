if (process.env.NODE_ENV === "production") {
  import("@index/helpers/metrics/yandex");
  import("@index/helpers/metrics/sentry");
}

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "@/assets/scss/normalize.scss";
import "@/assets/scss/main.scss";

Vue.config.productionTip = false;

Vue.mixin({
  computed: {
    $DICTIONARY() {
      return this.$store.state.dictionary.dictionary;
    },
  },
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
