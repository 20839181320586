<template>
  <div class="calculator">
    <div class="calculator__info">
      <span>{{ title }}:</span>
      <span class="calculator__sum">{{ loanSum | price }} ₽</span>
    </div>
    <VueSlider
      v-model="loanSum"
      :dotSize="28"
      :min="config.min"
      :max="config.max"
      :interval="config.step"
    />
    <div v-if="showRangeInfo" class="calculator__range-info">
      <span v-for="item in showRangeInfo" :key="item">
        {{
          item === 1
            ? config.min
            : ((config.max / (showRangeInfo - 1)) * (item - 1) || 0) | price
        }}
        ₽
      </span>
    </div>
  </div>
</template>

<script>
import "./calculator.scss";

import VueSlider from "vue-slider-component";
import price from "@index/components/helpers/string/price";
import { mapGetters } from "vuex";

export default {
  name: "Calculator",
  props: {
    model: {
      type: Number,
    },
    showRangeInfo: {
      type: Number,
      default: 0,
    },
  },
  model: {
    prop: "model",
    event: "change",
  },
  data() {
    return {
      config: {
        min: 1000,
        max: 100000,
        step: 1000,
      },
    };
  },
  created() {
    this.loanSum = this.calculator.amount;
  },
  computed: {
    ...mapGetters({
      calculator: "application/calculator",
    }),
    loanSum: {
      set(amount) {
        this.$emit("change", amount);
        this.$store.commit("application/updateCalculator", { amount });
      },
      get() {
        return this.model;
      },
    },
    title() {
      return this.$DICTIONARY.calculatorTitle;
    },
  },
  components: {
    VueSlider,
  },
  filters: {
    price,
  },
};
</script>
