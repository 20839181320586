import Cookies from "js-cookie";

export default {
  state: {
    dictionary: {},
  },
  mutations: {
    set(state, dictionary) {
      state.dictionary = dictionary;
    },
  },
  actions: {
    async init({ commit }) {
      const type = Cookies.get("sbg-cpa") ? "cpa" : "common";

      const runtimeConfig = await fetch(`/dictionary/${type}.json`);

      const dictionary = await runtimeConfig.json();

      commit("set", dictionary);
    },
  },
  namespaced: true,
};
