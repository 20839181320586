<template>
  <Final/>
</template>

<script>
import Final from "@/layouts/final/Final.vue";

export default {
  name: "App",
  components: {Final},
  watch: {},
};
</script>
