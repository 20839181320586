import getUser from "@index/api/getUser";
import lite from "@index/api/lite";
import send from "@index/api/send";
import Storage from "@index/ext/storage/Storage";
import Cookies from "js-cookie";

async function update(commit) {
  if (!Cookies.get("sbg-identity")) {
    await new Promise((resolve) => {
      setTimeout(() => {
        update(commit).then(resolve);
      }, 200);
    });

    return;
  }

  Cookies.set("sbg-tz", Intl.DateTimeFormat().resolvedOptions().timeZone);
  const application = await getUser();
  commit("update", application);
}

export default {
  state: {
    init: false,
    csrf: "",
    code_value: "",
    data: {
      contactData: {
        addrcity: "",
        birthday: "",
        email: "",
        firstname: "",
        gender: "",
        lastname: "",
        patronymic: "",
        phone: "",
      },
      passportData: {
        passportissuecode: "",
        passportnumber: "",
        passportseries: "",
      },
      isSigned: false,
    },
    term: 30,
    amount: 10000,
    loader: 0,
    showFinal: true,
  },
  getters: {
    isRefusenik({showFinal}) {
      return showFinal;
    },
    user({data}) {
      return data || {};
    },
    calculator({term, amount}) {
      return {
        term,
        amount,
      };
    },
    loader({loader}) {
      return !!loader;
    },
    csrf({csrf}) {
      return csrf;
    },
    code({code_value}) {
      return code_value;
    },
  },
  mutations: {
    clearCode(state) {
      Storage.delete("code_value");

      state.code_value = "";
    },
    load(state, flag) {
      if (flag) {
        state.loader++;
      } else {
        state.loader--;
      }

      if (state.loader < 0) state.loader = 0;
    },
    update(state, {csrf, code_value, questionnaire = {}}) {
      if (code_value) Storage.set("code_value", code_value);

      if (questionnaire.contactData?.code_hash)
        delete questionnaire.contactData.code_hash;
      if (questionnaire.contactData?.code)
        delete questionnaire.contactData.code;

      state.init = true;
      state.csrf = csrf || state.csrf;
      state.code_value = Storage.get("code_value") || state.code_value;

      state.data.contactData = {
        ...state.data.contactData,
        ...questionnaire.contactData,
      };
      state.data.passportData = {
        ...state.data.passportData,
        ...questionnaire.passportData,
      };
      state.data.isSigned = questionnaire.isSigned;
      state.amount = questionnaire.loanSum || state.amount;
      state.term = questionnaire.loanTerm || state.term;
    },
    updateCalculator(state, {term, amount}) {
      state.term = term || state.term;
      state.amount = amount || state.amount;
      Cookies.set("sum", amount);
    },
    showFinal(state, flag) {
      state.showFinal = flag;
    },
  },
  actions: {
    async update({commit}) {
      await update(commit);
    },
    async send({state, commit}, aData = {}) {
      const contactData = {
        ...state.data.contactData,
        ...aData.contactData,
      };

      const passportData = {
        ...state.data.passportData,
        ...aData.passportData,
      };

      const data = {
        csrf: state.csrf,
        loanSum: state.amount,
        loanTerm: state.term,
        contactData,
        passportData,
      };

      const application = await send(data);

      commit("update", {
        ...application,
        questionnaire: {
          contactData,
          passportData,
        },
      });

      return application;
    },
    async lite({state, commit}, aData = {}) {
      const contactData = {
        ...state.data.contactData,
        ...aData.contactData,
      };

      const passportData = {
        ...state.data.passportData,
        ...aData.passportData,
      };

      const data = {
        csrf: state.csrf,
        loanSum: state.amount,
        loanTerm: state.term,
        contactData,
        passportData,
      };

      const application = await lite(data);

      commit("update", {
        ...application,
        questionnaire: {
          contactData,
          passportData,
        },
      });

      return application;
    },
  },
  namespaced: true,
};
